import React from 'react';

const Framer = ({children}) => {

    return (
    <>
        {children}
    </>
    )
}

export default Framer