import emoji from "react-easy-emoji";
import resume from "./assets/File/MohammadAhmedCV.pdf";

const illustration = {
  animated: true,
};

const greeting = {
  username: "Mohammad Ahmed",
  title: "Hi all, I'm Mohammad Ahmed",
  subTitle: emoji(
    "A passionate MERN Stack Software Developer having an experience of designing and building Web and Mobile applications with React.js / Next.js / Javascript / Typescript / Nodejs and some other cool libraries and frameworks."
  ),
  resumeLink: resume,
  displayGreeting: true,
};

// Social Media Links
const socialMediaLinks = {
  github: "https://github.com/maddyahmad/",
  linkedin: "https://www.linkedin.com/in/maddyahmad/",
  gmail: "mohd.ahmed379@gmail.com",
  gitlab: "https://gitlab.com/maddyahmad/",
  facebook: "https://www.facebook.com/maddy.ahmad/",
  instagram: "https://www.instagram.com/maddy.ahmad/",
  twitter: "https://twitter.com/MaddyAhamad",
  display: true,
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle:
    "A MERN stack developer, wielding the power of MongoDB, Express.js, React, and Node.js to create captivating web applications.",
    skills: [
      emoji(
        "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
      ),
      emoji("⚡ State Management using Redux Toolkit, Zustand, and React Query"),
      emoji(
        "⚡ Integration of third-party UI libraries such as Material UI, Mantine UI, Shadcn, Formik, Firebase, and Tailwind CSS"
      ),
      emoji(
        "⚡ Implementation of API tools like TanStack Query (FKA React Query) for API calls that stale or cache response data, GraphQL, AWS-SDK for S3 Bucket, Azure Blob Services, and Firebase"
      ),
      emoji("⚡ Frontend Development using React.js, Next.js, and Storybook"),
      emoji("⚡ Backend Development using Node.js, MongoDB, and SQL"),
      emoji("⚡ Strong proficiency in JavaScript and TypeScript"),
      emoji(
        "⚡ Experience with development tools like GitHub, Bitbucket, Jira, Zoho, and npm"
      ),
      emoji("⚡ Building and managing private UI libraries"),
      emoji("⚡ Experience with monorepositories using NX for optimized productivity"),
      emoji("⚡ Working with SaaS-based Admin CMS solutions using Next.js and Laravel"),
      emoji("⚡ Performance optimization and Lighthouse improvements"),
    ],
    
    softwareSkills: [
      {
        skillName: "HTML5",
        fontAwesomeClassname: "fab fa-html5",
      },
      {
        skillName: "CSS3",
        fontAwesomeClassname: "fab fa-css3-alt",
      },
      {
        skillName: "SASS",
        fontAwesomeClassname: "fab fa-sass",
      },
      {
        skillName: "JavaScript & TypeScript",
        fontAwesomeClassname: "fab fa-js",
      },
      {
        skillName: "React.js & Next.js",
        fontAwesomeClassname: "fab fa-react",
      },
      {
        skillName: "Node.js",
        fontAwesomeClassname: "fab fa-node",
      },
      {
        skillName: "MongoDB",
        fontAwesomeClassname: "fas fa-database",
      },
      {
        skillName: "SQL",
        fontAwesomeClassname: "fas fa-database",
      },
      {
        skillName: "Git",
        fontAwesomeClassname: "fab fa-git-alt",
      },
      {
        skillName: "GitHub",
        fontAwesomeClassname: "fab fa-github",
      },
      {
        skillName: "Bitbucket",
        fontAwesomeClassname: "fab fa-bitbucket",
      },
      {
        skillName: "Jira",
        fontAwesomeClassname: "fab fa-jira",
      },
      {
        skillName: "Zoho",
        fontAwesomeClassname: "fas fa-cloud",
      },
      {
        skillName: "NPM",
        fontAwesomeClassname: "fab fa-npm",
      },
      {
        skillName: "Firebase",
        fontAwesomeClassname: "fas fa-fire",
      },
      {
        skillName: "AWS S3",
        fontAwesomeClassname: "fas fa-cloud-upload-alt",
      },
      {
        skillName: "Azure Blob Storage",
        fontAwesomeClassname: "fas fa-cloud",
      },
      {
        skillName: "GraphQL",
        fontAwesomeClassname: "fas fa-project-diagram",
      },
      {
        skillName: "Redux Toolkit",
        fontAwesomeClassname: "fas fa-layer-group",
      },
      {
        skillName: "Zustand",
        fontAwesomeClassname: "fas fa-store",
      },
      {
        skillName: "React Query",
        fontAwesomeClassname: "fas fa-sync-alt",
      },
      {
        skillName: "Storybook",
        fontAwesomeClassname: "fas fa-book",
      },
      {
        skillName: "NX Monorepos",
        fontAwesomeClassname: "fas fa-tree",
      },
    ],
  display: true,
};

// Education Section
const educationInfo = {
  display: true,
  schools: [
    {
      schoolName: "Graphic Era Hill University",
      logo: require("./assets/images/geulogo.png"),
      subHeader: "Bachelor of Technology in Mechanical Engineering",
      duration: "August 2013 - July 2017",
      desc: "Ranked top 10% in the program.",
      descBullets: [
        " Completed Project on Solar AC Technolgy and completed one month internship at Ordinance Factory Board ( OLF ) in the fields Macine Control and Production",
      ],
    }
  ],
};

const techStack = {
  viewSkillBars: true,
  experience: [
    {
      Stack: "Frontend / Design",
      progressPercentage: "75%",
    },
    {
      Stack: "Backend",
      progressPercentage: "50%",
    },
    {
      Stack: "Devops",
      progressPercentage: "50%",
    },
  ],
  displayCodersrank: true,
};

const workExperiences = {
  display: true,
  experience: [
    {
      role: "Software Development Engineer - SDE III (Tawuniya)",
      company: "Tawuniya",
      companylogo: require("./assets/images/tawuniya-logo.jpg"),
      date: "April 2024 - Present",
      desc: "Working as a Senior Frontend Developer, specializing in JavaScript frameworks such as React.js, Next.js, and Node.js. Focused on enhancing user experience, optimizing performance, and driving innovation in insurance technology solutions.",
      descBullets: [
        "Leading the development of user-centric web applications, improving performance, and ensuring seamless UI/UX experiences.",
        "Developing and maintaining a private UI component library to streamline development and enhance code reusability.",
        "Increased user engagement on the e-service portal by 35% and significantly reduced bounce rates.",
        "Designed and implemented a reusable private UI library, reducing development time by 40%.",
        "Optimized application performance, improving Lighthouse scores from 27% to 78%.",
        "Enhanced policy renewal rates by 55% through a seamless e-service portal experience.",
        "Successfully integrated ZATCA compliance for bulk endorsements via Corporate and Partner portals.",
      ],
    },
    {
      role: "Software Consultant",
      company: "Cynoteck Technology Solutions Private Limited",
      companylogo: require("./assets/images/cynoteckLogo.png"),
      date: "October 2021 – April 2024",
      desc: "Worked as a MERN Full Stack Developer, contributing to the development of scalable SaaS products and high-performance web applications. Led frontend teams and optimized productivity through modern frameworks and tools.",
      descBullets: [
        "Developed and maintained SaaS-based admin CMS solutions using Next.js and Laravel, improving user management efficiency by 30%.",
        "Architected and optimized high-performance web applications, enhancing user experience and engagement.",
        "Led a team of 5 frontend developers, ensuring best practices, code quality, and timely product deliveries.",
        "Worked extensively with multiple libraries and frameworks to develop scalable and maintainable products.",
        "Achievements: Won the Employee of the Month award twice for outstanding contributions.",
      ],
    },
    {
      role: "Software Developer",
      company: "LorryGo Technology Private Limited",
      companylogo: require("./assets/images/lorrygo.jpg"),
      date: "January 2020 – June 2021",
      desc: "Worked as a Web Developer, specializing in building responsive and dynamic web applications using React.js, JavaScript, HTML, CSS, and various UI libraries.",
      descBullets: [
        "Developed and enhanced web applications for logistics platforms, improving mobile traffic and cargo movement.",
        "Built interactive and user-friendly interfaces using modern UI frameworks and libraries.",
        "Optimized frontend performance to enhance user experience and application efficiency.",
        "Contributed to the development of financial systems and online presence for various businesses.",
      ],
    },
    
  ],
};

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "maddyahmad",
  showGithubProfile: "true",
  display: false,
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "Some major projects that I have worked on",
  projects: [
    {
      image: require("./assets/images/tawuniya-logo.jpg"),
      projectName: "Tawuniya CXP, PXP, SME, SME Sales",
      projectDesc:
        "Tawuniya is one of the leading insurance providers in Saudi Arabia. Developed multiple platforms, including CXP, PXP, SME, and SME Sales, to enhance customer experience and streamline insurance policy management. Implemented a private UI library '@tawniya/ui' to optimize development efficiency and improve user engagement.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.tawuniya.com/",
        },
      ],
    },    
    {
      image: require("./assets/images/onekeymls-logo.webp"),
      projectName: "Onekey LMS (Property Search)",
      projectDesc:
        "OneKey® MLS is The One source real estate marketplace with The Best Data, Expertise, and Innovation. They provide  listings in metropolitan New York, straight from the largest REALTOR®-run MLS in the Empire state.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://onekeymls.com/",
        },
      ],
    },
    {
      image: require("./assets/images/opencounty.png"),
      projectName: "OI SaaS County",
      projectDesc:
        "The Open County portal has been developed for the Council of Governors by the Open Institute with support from the World Bank’s Kenya Accountable Devolution Program and funding from Danida, DFID, EU, Finland, Sida, and USAID.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://opencounty.org/",
        },
      ],
    },
    {
      image: require("./assets/images/gaspos.jpg"),
      projectName: "GasPos",
      projectDesc: `Gas Pos: Fuel POS + EMV Pumps For Gas Stations & truck Stops Across USA. With the Gas Pos Equipment as a Service Program, you will receive the fastest-growing fuel point of sale system, EMV fuel dispensers, and the most advanced back office software for gas stations & truck stops.`,
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.gaspos.co/",
        },
      ],
    },
    {
      image: require("./assets/images/coinsuites.jpg"),
      projectName: "Coinsuites",
      projectDesc:
        "on a mission to help entrepreneurs and small businesses find real growth through building Fundable businesses and accessing capital.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://onboarding.coinsuites.com/",
        },
      ],
    },
    {
      image: require("./assets/images/tpologo.png"),
      projectName: "The Patient Offer",
      projectDesc:
        "TPO's “Willingness-to-Pay” Quotient of Payers is a proactive approach that helps move the decisions to the front end of care spectrum. Thereby, enhance the overall Patient engagement experience & Provider’s bottom line.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://thepatientoffer.com/index.html",
        },
      ],
    },
    {
      image: require("./assets/images/ThePBNetworkLogo.webp"),
      projectName: "The PB Network",
      projectDesc:
        "The PB Network provides meaningful daily activities for Day Program participants, Residences, and individuals to participate in community involvement, gaining confidence, working with others, sharing ideas, developing verbal, and writing skills and having fun!",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://thepbnetwork.com/",
        },
      ],
    },
  ],
  display: true,
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Recognitions, certifications, and milestones that highlight my expertise and contributions!",

  achievementsCards: [
    {
      title: "Employee of the Month - Cynoteck",
      subtitle:
        "Recognized twice as Employee of the Month for exceptional performance and contributions.",
      footerLink: [
        {
          name: "Award Details",
          url: "",
        },
      ],
    },
    {
      title: "Certificate of Excellence - Tawuniya",
      subtitle:
        "Received twice for outstanding work in frontend development and UI optimizations.",
      footerLink: [
        {
          name: "Certificate",
          url: "",
        },
      ],
    },
    {
      title: "Performance Optimization - Tawuniya",
      subtitle:
        "Improved application performance from 27% to 78% using advanced frontend optimization techniques.",
      footerLink: [
        {
          name: "Performance Report",
          url: "",
        },
      ],
    },
    {
      title: "SQL Certification - IBM (Edx)",
      subtitle:
        "Earned SQL certification from IBM, demonstrating proficiency in database management and querying.",
      footerLink: [
        {
          name: "Certification",
          url: "",
        },
      ],
    },
    {
      title: "Private UI Library Development",
      subtitle:
        "Designed and implemented '@tawniya/ui', a reusable UI component library, reducing development time by 40%.",
      footerLink: [
        {
          name: "Project Details",
          url: "",
        },
      ],
    },
  ],
  display: true,
};


// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(""),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: "",
    },
  ],
  display: false,
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [""],
  display: false,
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-8126377488, +966-500754503",
  email_address: "mohd.ahmed379@gmail.com",
};

// Twitter Section

const twitterDetails = {
  userName: "MaddyAhamad",
  display: true,
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
};
