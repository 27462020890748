import React, {useContext} from "react";
import "./Footer.css";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";
import Framer from "../FramerComponent";

export default function Footer() {
  const {isDark} = useContext(StyleContext);
  return (
    <Framer>
      <div className="footer-div">
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {emoji("Made by @Mohammad Ahmed")}
        </p>
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          Theme by{" "}
          <a href="https://linkedin.com/in/maddyahmad">
            Mohammad Ahmed
          </a>
        </p>
      </div>
    </Framer>
  );
}
